
import Vue from 'vue'
export default Vue.extend<Data, Methods, Computed>({
  data() {
    return {
      name: '',
      count: 0,
      list: [],
      GAData: {
        location: `${process.env.VUE_APP_CELLOOK_URL}/tracking/test`,
        path: '/tracking/test',
      },
    }
  },
  created() {
    if (process.env.VUE_APP_ENV === 'production') {
      this.$trackingPromotion({ ...this.GAData, title: 'test_ace_counter' })
    }
  },
  methods: {
    async send() {
      if (!this.name) return alert('이벤트 명을 입력해주세요.')
      if (!this.count) return alert('전송할 이벤트의 수를 입력해주세요.')
      try {
        for (let i = 1; i <= this.count; i++) {
          try {
            await this.$tracking(this.name)
            this.list.push(`${this.name} - ${i} SUCCESS`)
          } catch (e) {
            this.list.push(`${this.name} - ${i} FAIL`)
          }
        }
      } finally {
        const scrollContainer = this.$refs.scrollContainer as HTMLDivElement
        scrollContainer.scrollTo({ top: scrollContainer.scrollHeight })
      }
    },
  },
})

interface Data {
  name: string
  count: number
  list: string[]
  GAData: {
    location: string
    path: string
  }
}
interface Methods {
  send(): void
}
interface Computed {}
