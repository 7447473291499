
import Vue from 'vue'
import Tracking from '@/components/views/tracking/Tracking.vue'
export default Vue.extend<Data, Methods, Computed>({
  components: { Tracking },
})

interface Data {}
interface Methods {}
interface Computed {}
